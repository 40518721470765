@import url("https://pro.fontawesome.com/releases/v6.0.0-beta1/css/all.css");
@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@300;500;700&display=swap");

#home{
  width: 100%;
  float: left;
  height: 30vh;
  position: relative;
  margin-bottom: 3%;
  background-color: aliceblue;
}
.home-text{
  margin-top: 1%;
  text-align: center;
  font-size: 30px;
  margin-bottom: 1rem;
  font-weight: 600;
color: #875454;
font-family:'Times New Roman', Times, serif;
}
.home-text2{
  text-align: justify;
  margin-left: 2%;
  margin-right: 3%;
  margin-bottom: 1rem;
  font-weight: 200;
color: #000000;
font-family:'Times New Roman', Times, serif;
font-size: 22px;
}
.list{
  display: flex;
  flex-direction: row;
  list-style: none;
  align-items: center;
}
.list li{
  padding: 20px 30px;
  font-size: 18px;
  list-style: none;
  font-weight: 400;
color: #f20000;
font-family:'Times New Roman', Times, serif;
}
.about-text{
  font-size: 30px;
  margin-bottom: 1rem;
  font-weight: 600;
color: #B52B65;
font-family:'Times New Roman', Times, serif;
 text-align: center;
}
.about-text2{
  font-size: 22px;
  margin-bottom: 1rem;
  font-weight: 500;
color: #6b4f5b;
font-family:'Times New Roman', Times, serif;
 text-align: center;
}
.about-text3{
  font-size: 25px;
  margin-bottom: 1rem;
  font-weight: 500;
color: #B52B65;
font-family:'Times New Roman', Times, serif;
 text-align: center;
}
#about{
  float: left;
  width: 100%;
  height: 170vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
 
}
.card{
  margin-top: 5%;
  display: flex;
  margin-left: 3%;
  height:350px;;
  width: 30%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.card-title{
font-size: 18px;
font-weight: 500;
color:rgb(255, 149, 0) ;
}
.card-text{
  text-align: justify;
  font-size: 300;
  font-size: 14px;
}

.card:nth-child(n+4) {
  margin-top:-2%; /* Adjust the margin for the second row */
}
.card-text li{
  list-style: none;
  margin-bottom: 1%;
}
h1{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #101010;
}
.first-part{
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
 align-items: center;   
}
.img{
  width: 70%;
  margin-top: 5%;
  border-radius: 2%;

}
/* 
.first-text{
margin-top: 3%;
color: red;
font-size: 35px;
font-weight: 500;
font-family: 'Courier New', Courier, monospace;
}
.text{
  color: #5a3d3d;
  font-size: 25px;
  margin-bottom: 1rem;
  font-weight: 500;
font-family:Comic Sans MS, Comic Sans, cursive;
}
.box {
  background-color: #E34A27;
  padding: 13px;
  border-radius: 35px;
  text-align: center;
  width: 15%;
  height: 50px;
  margin-bottom: 1rem;
}
.text2 {
    font-size: 20px;
    font-weight: 500;
  font-family:Comic Sans MS, Comic Sans, cursive;
    color: rgb(247, 237, 237);
  }
  .text3{
    font-size: 30px;
    font-weight: 500;
  font-family:'Impact';
  color: #B52B65;
  }
  .text4{
    font-size: 20px;
    font-weight: 500;
  font-family:'Impact';
  color: #060606;
  } */
  /* .first-img{
    margin-top: 2%;
    width: 15%;
  
  } */
  .second-part{
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    margin-bottom: 10px;
    background-color: #603366;
  }
  .part{
    display: flex;
    flex-direction: row;
    list-style: none;
    align-items: center;
   
  }
  .part li{
    padding: 30px 60px;
    font-size: 25px;
    color: white;
  }
  #numbers{
    width: 100%;
    float: left;
    height: 55vh;
    display: flex;
    flex-direction: column;
    margin-bottom: 10%;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: rgb(255, 255, 255);
  }
  .number-container{
    font-weight: 600;
    color: rgb(255, 166, 0);
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
    font-size: 30px;
 
    
  }
  .table-container{
    width: 80%;
   
  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
   margin-bottom: 3%;
  }
  
  td, th {
    text-align: center;
    padding: 8px;
  }
  th{
    
    font-size: 22px;
    background-color: rgb(255, 166, 0);
    color: #fff;
    border-top:1px solid #000000 ;
    border-bottom: 1px solid #000000;
  }
  .table-container tbody tr:last-child {
    border-top: 1px solid #000;
  }
td{
  font-size: 18px;
}

#contact{
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vh;
  position: relative;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 2);
}
.detail{
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  color: #000;
}
.bn46 {
  margin-top: 5%;
  width: 170px;
  height: 50px;
}
.center{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}
.btn {
  background-color: rgb(255, 166, 0);
  border: none;
  color: white;
  padding: 10px 30px;
 pointer-events: none;
  font-size: 20px;
  margin-bottom: 2%;
}

/* Darker background on mouse-over */

/*------------------------------------------------------------------------------- RESPONSIVNESS------------------------------------------------------------------------------------------------------------------------------- */

@media (max-width:768px){
  .first-text {
    margin-top: 15%;
    color: red;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Courier New', Courier, monospace;
}
.text {
  color: #5a3d3d;
  font-size: 18px;
  margin-bottom: 1rem;
  font-weight: 500;
  font-family: Comic Sans MS, Comic Sans, cursive;
}
.box {
  background-color: #E34A27;
  padding: 12px;
  border-radius: 35px;
  text-align: center;
  width: 40%;
  height: 45px;
  margin-bottom: 1rem;
}
.text2 {
  font-size: 16px;
  font-weight: 500;
  font-family: Comic Sans MS, Comic Sans, cursive;
  color: rgb(247, 237, 237);
}
.text3 {
  font-size: 20px;
  font-weight: 500;
  font-family: 'Impact';
  color: #B52B65;
}
.text4 {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Impact';
  color: #060606;
}
/* .first-img {
  margin-top: 2%;
  width: 40%;
} */
.second-part {
  margin-top: 3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  background-color: #603366;
}
.part {
  display: flex;
  flex-direction: row;
  list-style: none;
  align-items: center;
  width: 100%;
}
.part li {
  padding: 15px 8px;
  font-size: 0.8rem;
  color: white;
}
#home {
  width: 100%;
  float: left;
  height: 40vh;
  position: relative;
  margin-bottom: 3%;
  background-color: aliceblue;
}
.home-text {
  margin-top: 1%;
  text-align: center;
  font-size: 25px;
  margin-bottom: 1rem;
  font-weight: 600;
  color: #875454;
  font-family: 'Times New Roman', Times, serif;
}
.home-text2 {
  text-align: justify;
  margin-left: 2%;
  margin-right: 3%;
  margin-bottom: 1rem;
  font-weight: 200;
  color: #000000;
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
.list {
  display: flex;
  flex-direction: column;
  list-style: none;
  align-items: center;
}
.list li {
  padding: 5px 15px;
  font-size: 14px;
  list-style: none;
  font-weight: 400;
  color: #f20000;
  font-family: 'Times New Roman', Times, serif;
}
.about-text {
  font-size: 20px;
  margin-bottom: 1rem;
  font-weight: 600;
  color: #B52B65;
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
}
.about-text2 {
  font-size: 16px;
  margin-bottom: 1rem;
  font-weight: 500;
  color: #6b4f5b;
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
}
.about-text3 {
  font-size: 18px;
  margin-bottom: 1rem;
  font-weight: 500;
  color: #B52B65;
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
}
#about {
  float: left;
  width: 100%;
  height: 530vh;
  display: flex;
  flex-direction:row;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 0%;
}
.card {
  margin-top: 5%;
  display: flex;
  margin-left: 3%;
  height: 400px;
  width: 95%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.card:nth-child(n+4) {
  margin-top:5%; /* Adjust the margin for the second row */
}
.table-container {
  width: 80%;
}
table {
  border-collapse: collapse;
  box-shadow: 0 2px 4px #0000004d;
  font-family: arial, sans-serif;
  width: 0%;
}
th {
  font-size: 14px;
  background-color: rgb(255, 166, 0);
  color: #fff;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
}
td {
  font-size: 14px;
}
#numbers{
  width: 100%;
  float: left;
  height: 60vh;
  display: flex;
  margin-bottom: 0%;
  justify-content:flex-start;
  align-items:center;
  margin-top: 0%;
  margin-left: 0%;
  position: relative;
  background-color: rgb(255, 255, 255);
}
.detail {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: #000;
}
.btn {
  background-color: rgb(255, 166, 0);
  border: none;
  color: white;
  padding: 8px 20px;
  cursor: pointer;
  font-size: 13px;
  margin-bottom: 2%;
}

/* Darker background on mouse-over */
.btn:hover {
  background-color: rgb(255, 166, 0);
  color: #fff;
}
.bn46 {
  margin-top: 5%;
  width: 140px;
  height: 40px;
}
}

/*------------------------------------------------------------------------------- RESPONSIVNESS desktop------------------------------------------------------------------------------------------------------------------------------- */



@media (min-width: 769px) and (max-width: 1024px){
  .box {
    background-color: #E34A27;
    padding: 13px;
    border-radius: 35px;
    text-align: center;
    width: 18%;
    height: 50px;
    margin-bottom: 1rem;
}
#home {
  width: 100%;
  float: left;
  height: 12vh;
  position: relative;
  margin-bottom: 3%;
  background-color: aliceblue;
}
.second-part {
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 65px;
  margin-bottom: 10px;
  background-color: #603366;
}
.part li {
  padding: 30px 35px;
  font-size: 22px;
  color: white;
}
.home-text2 {
  text-align: justify;
  margin-left: 2%;
  margin-right: 3%;
  margin-bottom: 1rem;
  font-weight: 200;
  color: #000000;
  font-family: 'Times New Roman', Times, serif;
  font-size: 20px;
}
.list li {
  padding: 20px 11px;
  font-size: 15px;
  list-style: none;
  font-weight: 400;
  color: #f20000;
  font-family: 'Times New Roman', Times, serif;
}
.about-text2 {
  font-size: 20px;
  margin-bottom: 1rem;
  font-weight: 500;
  color: #6b4f5b;
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
}
#about {
  float: left;
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
}
.card {
 
  display: flex;
  margin-left: 3%;
  height: 380px;
  width: 45%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.card:nth-child(n+4) {
  margin-top:5%; /* Adjust the margin for the second row */
}
#numbers {
  width: 100%;
  float: left;
  height: 25vh;
  margin-top: 20%;
  display: flex;
  margin-bottom: 5%;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: rgb(255, 255, 255);
}
}