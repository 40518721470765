 .navbar-container{
    width: 100%;
    height: auto;
    position: relative;
    background-color: #ffffff;
    display: flex; 
    align-items: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    justify-content: center; 
  }
  
  h1{
      color: rgb(247, 237, 237);
  }
  
  #menu li{
    list-style: none;
    display: inline-block;
    margin: 20px 90px;
}
.first-img {
    
        width: 100px; /* Set the desired width */
        height: auto; /* Maintain the aspect ratio */
        margin-top: 1%; /* Adjust the margin as needed */
        margin-bottom: 0.5%;
    }

    

#menu li a{
    color: #232020;
    text-decoration: none;
    font-size: 20px;
} 

.hamburger-menu {
    display: none;
    cursor: pointer;
}
@media  (max-width: 768px) {
    .navbar-container{
        width: 100%;
        height: 70px;
        position: relative;
        background-color: #ffffff;
        display: flex; 
        align-items: center;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        justify-content: left; 
      }
    #menu {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 50px; 
        left: 0;
        width: 100%;
        background-color: #ffffff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    }

    #menu.active {
        display: flex;
       
    }

    .hamburger-menu {
        display: block;
        margin-left: 12rem;
        
    }
    .hamburger-menu svg {
        fill: rgb(126, 83, 2); 
    }
    .first-img {
        width: 65px;
        flex-direction: column;
        height: auto;
        display: flex;
        margin-top: 0;
        align-items: center;
        margin-left: 30px;
        margin-bottom: 0.5%;
}
}
@media (min-width: 769px) and (max-width: 1024px){
    .navbar-container{
        width: 100%;
        height: 90px !important;
        position: relative;
        background-color: #ffffff;
        display: flex; 
        align-items: center;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        justify-content: left;  
      }
      
      h1{
          color: rgb(247, 237, 237);
      }
      
      #menu li{
        list-style: none;
        display: inline-block;
        margin: 20px 60px;
    }
    
    #menu li a{
        color: #232020;
        text-decoration: none;
        font-size: 18px;
    } 
  .first-img {
            width: 85px;
            flex-direction: column;
            height: auto;
            display: flex;
            margin-top: 0;
            align-items: center;
            margin-left: 30px;
            margin-bottom: 0.5%;
    }
}
